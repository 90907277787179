$env: 0;
.Nav-title {
  font-size: 18px;
  color: black;
}
//back button in navbar
.back-nav {
  border: 1px solid black;
  border-radius: 10px;
  padding: 3px 6px;
}
.nav-heading {
  margin-left: 20px;
  font-size: 10px;
  font-weight: 300;
}
.nav-breadcrumbs {
  .breadcrumb-separator {
    margin: 0 5px;
    color: #a4a7b6;
  }
  ol {
    margin-left: 20px;
    font-size: 12px;
    font-weight: 700;
    a {
      text-decoration: none;
      color: #a4a7b6;
    }
    .active-route {
      color: #43465c;
    }
  }
}
.dropdown-menu-search {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  padding: 0px 20px;

  input {
    box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
    border-radius: 50px;
    width: 100%;
    height: 40px;
  }
  figure {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.quick-add-dropdown {
  .dropdown-menu {
    top: 72px !important;
    left: -78px !important;
    min-width: 19rem !important;
  }
  .dropdown-menu-small {
    top: 72px !important;
    left: -185px !important;
    min-width: 18rem !important;
  }
  .list-item {
    display: flex;
    align-items: center;
    svg {
      width: 18px;
      height: 18px;
      color: black !important;
    }
    svg path {
      fill: #000000;
    }
    .list-title {
      margin-left: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /* Set the desired width or max-width to define the boundary */
      width: 100%;
    }
  }
}
.user-info {
  // background-color: #43465c;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  padding: 0px 20px;
  // margin: 900px 0px !;
  display: flex;
  align-items: center;
  figure {
    margin: 0;
    margin-right: 10px;
  }
  .hed {
    color: #4f4f4f;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    margin: 0;
  }

  .role {
    color: #007481;
    font-family: Inter;
    font-size: 9px;
    margin: 0;
    font-weight: 400;
  }
}

.role-select-dropdown {
  padding-right: 10px;
  .react-select {
    width: 170px;
  }
}
