@import "./components/listing/style";

.list-info {
  font-weight: 400;
  font-size: 14px;
  color: #717579;
}

//menue collapser
.collapsToggler {
  height: 30px;
  margin-right: 20px;

  .rotate {
    transform: rotate(180deg);
  }
}

//language switcher
.CustomLanguage {
  cursor: pointer;

  display: flex;
  align-items: center;
  .languagetext {
    // height: 17px;
    font-weight: bold;
    font-size: 16px;
  }
}

.export-button {
  // background-color: #677888 !important;
  background-color: #f4f5f6 !important;
  // padding: 2px;
  height: 40px !important;
  width: 40px !important;
  // margin
  border: none !important;
  // margin-left: 10px !important;
  // align-self: center;
  position: relative;
  .icon {
    position: absolute;
    left: 20%;
    top: 18%;
  }
}

.react-select-paginate {
  .css-1pahdxg-control {
    box-shadow: unset !important;
    border-color: #B6A884 !important;
  }
  .css-1n7v3ny-option {
    background-color: rgba(16, 169, 69, 0.12);
    color: #B6A884;
  }
  .css-9gakcf-option {
    background-color: #B6A884;
  }
}

.submission-review {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }

  .submission-details {
    box-shadow: none !important;
    border: 1.75px solid #e7e7e7;
    width: 100%;
    padding-top: 2rem;
    background-color: #fff;
    border-radius: 3px;
    padding: 2rem;
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #e8e8e8;

      .title {
        .label {
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.5px;
          color: #484556;
        }
        .value {
          font-weight: 600;
          font-size: 24px;
          line-height: 20px;
          letter-spacing: 0.5px;
          color: #000000;
        }
      }
    }
    .center-content {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: start;
      .label {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #6e7191;
      }

      .value {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #1c1b1f;
      }

      .steps {
        display: flex;
        flex-wrap: wrap !important;
        justify-content: start;
        //   max-width: 300px;
        .step {
          margin-right: 5px;
          font-weight: 500;
          font-size: 16px;
          /* identical to box height */
          padding: 5px 10px;
          width: fit-content !important;
          background: #f2f2f2;
          border-radius: 5px;

          color: #9b9b9b;
        }
      }
    }
  }

  .approval-buttons {
    display: flex;
    justify-content: center;
    // margin-top: 2rem;
  }

  .review-message {
    margin-top: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-header {
  background-color: #007bff;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.signature {
  // border: 2px solid;
  // border-color: $primary;
  // border-radius: 5px;

  width: 100%;
  height: 50px;
  overflow: hidden;

  &-canvas {
    width: 100%;
    height: 99px;
  }
}