$env: 0;
.card-rounded {
  border-radius: 20px;
  border: 1px solid #e2e4e9;
  background: #fff;
  padding: 15px 20px;
}

.statis-section {
  .title {
    color: #161922;
    margin: 0;
  }

  .statis-badge {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 32px;
    background: #e1fcef;
    color: #0a0d14;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.3px;
    figure {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
  padding-bottom: 8px;
  border-bottom: solid 1px #e2e4e9;
}

.widget-card {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: 80px;

  figure {
    margin: 0;
  }

  @media (max-width: 768px) {
    &:not(:last-child) {
      border: none !important; // Adjust thickness and color as needed
    }
    justify-content: start;
    margin-left: 30px;
  }

  &:not(:last-child) {
    border-right: 2px dotted #e2e4e9; // Adjust thickness and color as needed
  }
  //   &:not(:first-child) {
  //   }

  padding: 8px 0px 10px 0px; // Adjust thickness and color as needed

  .count {
    color: #000;
    // font-family: Mazzard;
    font-size: 50px;
    font-style: normal;
    font-weight: 275;
    letter-spacing: -2.05px;
    margin: 0;
    margin-top: 20px;
  }
  .type {
    color: #7e808a;
    font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    // line-height: 50px; /* 111.111% */
    margin: 0;
    margin-top: 20px;
    margin-left: 5px;
  }
}

.graph {
  flex-grow: 1; /* Ensures all graphs take up equal width */
  max-width: calc(33.333% - 10px);

  @media (max-width: 1150px) {
    width: 100%;
    max-width: unset;
  }
  .head {
    display: flex;
    justify-content: space-between;
    .title {
      margin: 0;
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
      letter-spacing: -0.198px;
    }
    .legend {
      display: flex;
      justify-content: space-between;
    }
  }

  // &:first-child {
  //   padding-left: 0 !important;
  // }
}
.chart-container {
  margin: 0 auto;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    font-size: 18px;
    font-weight: bold;
  }
}

.chart-legend {
  display: flex;
  align-items: center;

  .legend-item {
    display: flex;
    align-items: center;
    // margin-right: 15px;

    &:first-child {
      margin-right: 10px;
    }

    span {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    .gradient-legend {
      border-radius: 50%;
    }

    .pattern-legend {
      background: repeating-linear-gradient(
        45deg,
        #616161 0.5px,
        transparent 3px,
        transparent 4px
      );
      border-radius: 50%;
    }
  }
}

.bar-chart {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  // height: 300px;
  margin-top: 20px;
  min-height: 200px;
}

.target-bar {
  &-detection {
    background: linear-gradient(360deg, #d9d9d9 0%, #df1c41 100%);
  }
  &-notification {
    background: linear-gradient(360deg, #d9d9d9 0%, #f2ae40 100%);
  }
  &-response {
    background: linear-gradient(360deg, #d9d9d9 0%, #2d9f75 100%);
  }
  transition: height 0.3s ease;
}

.bar-group {
  display: flex;
  align-items: flex-end;
  width: 100px;
  max-height: 300px;
  justify-content: center;
  // overflow: hidden;

  .bar {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 8px;
    position: relative;
    margin-bottom: 10px;

    span {
      position: absolute;
      top: -20px;
      color: #000;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .target-bar {
    &-detection {
      background: linear-gradient(360deg, #d9d9d9 0%, #df1c41 100%);
    }
    &-notification {
      background: linear-gradient(360deg, #d9d9d9 0%, #f2ae40 100%);
    }
    &-response {
      background: linear-gradient(360deg, #d9d9d9 0%, #2d9f75 100%);
    }
    transition: height 0.3s ease;
  }

  .percentage-bar {
    margin-bottom: 10px;
    transition: height 0.3s ease;
    background-attachment: fixed;

    background-image: url("../../../assets/images/patteren.png");
  }

  .bar-label {
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    color: #161922;
  }
}

.info-section {
  .title {
    color: #161922;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }

  .pecentage {
    color: #ee0004;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
  }

  .info {
    color: #868c98;
    font-size: 12px;
    font-weight: 300;
  }

  .days-info {
    font-size: 40px;
    font-weight: 275;
    line-height: 50px;
    letter-spacing: -0.041em;
    color: #000;
    margin: 0;

    span {
      font-size: 16px;
    }
  }
}
.horizontal-devider {
  height: 90px;
  border-left: 2px dotted #e2e4e9;
  margin: 10px 10px;
}

.info-section-container {
  padding-top: 15px;
  padding-bottom: 8px;
  border-top: 2px dotted #e2e4e9;
  border-bottom: 2px dotted #e2e4e9;
}

.bottle-neck-section {
  .title {
    margin: 0;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.198px;
  }

  .badge-custom {
    background: #f8c9d2;
    color: #710e21;
    font-size: 13px;
    font-weight: 500;
    text-align: start;
    padding: 6px 14px 6px 14px;
    border-radius: 30px;
  }
}

.graph-containers {
  display: flex;
  justify-content: space-between; /* Distribute space between items */
  gap: 10px; /* Add spacing between graph items */
  @media (max-width: 1150px) {
    flex-direction: column;
  }
}

.search_box {
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e2e4e9;
  background: #fff;
  position: relative;

  .dropdown {
    padding-block: 8px;
    background: #f6f8fa;
    border-radius: 6px 0px 0px 6px;
    cursor: pointer;
    min-width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e2e4e9;
  }

  .custom-flter-select__control {
    width: 280px;
    border: none !important;
    padding-right: 30px;
    background-color: transparent;
  }

  .searchIcon {
    // margin-left: -30px;
    cursor: pointer;
    // margin-top: 5px;
    // padding: 6px;
    position: absolute;
    right: 10px;
    top: 7px;
  }

  .custom-flter-select__indicator,
  .custom-flter-select__indicators {
    display: none !important;
  }
}

.plus_btn {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 6px;
  border-radius: var(--radius-8, 8px);
  border: 1px solid var(--stroke-soft-200, #e2e4e9);
  background: var(--bg-white-0, #fff) !important;
  box-shadow: 0px 1px 2px 0px rgba(82, 88, 102, 0.06);
}
